// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// RMO
// yarn add @fortawesome/fontawesome-free jquery popper.js bootstrap
import 'bootstrap'
import "../stylesheets/application"
import "./custom"
import "@fortawesome/fontawesome-free/js/all";
require.context('../images', true)
// Add this to avoid '.modal("toggle")' is not a function
import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
require("moment/locale/ja")
require("tempusdominus-bootstrap-4")


Rails.start()
Turbolinks.start()
ActiveStorage.start()
